import React, { useState, useEffect } from 'react'
import { Loader2, ArrowRight, Star, Shield, TrendingUp, BarChart2, Users, Compass, Download } from 'lucide-react'
import { jsPDF } from 'jspdf'
import './index.css'

type FormData = {
  ideaDescription: string
  targetMarket: string
  businessModel: string
  uniqueSellingPoint: string
}

const initialFormData: FormData = {
  ideaDescription: '',
  targetMarket: '',
  businessModel: '',
  uniqueSellingPoint: ''
}

export default function StartupIdeaAnalyzer() {
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [analysis, setAnalysis] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [apiKey, setApiKey] = useState<string | null>(null)

  useEffect(() => {
    const key = process.env.REACT_APP_GEMINI_API_KEY
    if (key) {
      setApiKey(key)
    } else {
      console.error('API key is not set in environment variables')
      setError('API key is not configured. Please check your environment setup.')
    }
  }, [])

  const logAnalyticsEvent = (event: string, params?: { [key: string]: any }) => {
    if (window.gtag) {
      window.gtag('event', event, params);
    } else {
      console.error('gtag is not available for tracking events');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
    setError(null)
  }

  const validateStep = () => {
    switch (step) {
      case 1:
        if (formData.ideaDescription.length < 50) {
          setError("Please provide a more detailed description (at least 50 characters).")
          return false
        }
        break
      case 2:
        if (formData.targetMarket.length < 20) {
          setError("Please provide more information about your target market (at least 20 characters).")
          return false
        }
        break
      case 3:
        if (!formData.businessModel) {
          setError("Please select a business model.")
          return false
        }
        if (formData.uniqueSellingPoint.length < 20) {
          setError("Please provide a more detailed unique selling point (at least 20 characters).")
          return false
        }
        break
    }
    return true
  }

  const handleNext = () => {
    if (validateStep()) {
      logAnalyticsEvent('step_navigation', { step: step, action: 'next' });
      setStep(prevStep => prevStep + 1)
    }
  }

  const handlePrevious = () => {
    logAnalyticsEvent('step_navigation', { step: step, action: 'previous' });
    setStep(prevStep => prevStep - 1)
    setError(null)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (validateStep()) {
      setIsLoading(true)
      setError(null)
      try {
        if (!apiKey) {
          throw new Error('API key is not set')
        }

        const prompt = `Analyze the following startup idea:
        Idea Description: ${formData.ideaDescription}
        Target Market: ${formData.targetMarket}
        Business Model: ${formData.businessModel}
        Unique Selling Point: ${formData.uniqueSellingPoint}

        Provide a detailed analysis including:
        1. Market Size and Growth Potential
        2. Competitor Analysis
        3. Unique Value Proposition
        4. Potential Risks and Challenges
        5. Next Steps and Recommendations`

        const response = await fetch(`https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${apiKey}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contents: [{ parts: [{ text: prompt }] }]
          })
        })

        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`)
        }

        const data = await response.json()
        setAnalysis(data.candidates[0].content.parts[0].text)
        setStep(4)
      } catch (error) {
        console.error('API call error:', error)
        setError("An error occurred while generating the analysis. Please try again later.")
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleDownload = () => {
    if (analysis) {
      const doc = new jsPDF()

      // Add watermark
      doc.setTextColor(200)
      doc.setFontSize(40)
      doc.text('StatAI', 105, 150, { align: 'center', angle: 45 })

      // Reset text color and font size for content
      doc.setTextColor(0)
      doc.setFontSize(12)

      const splitText = doc.splitTextToSize(analysis, 180)
      doc.text(splitText, 15, 15)

      doc.save('startup-analysis.pdf')
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="ideaDescription" className="block text-sm font-medium text-gray-700">Describe your startup idea</label>
              <textarea
                id="ideaDescription"
                name="ideaDescription"
                value={formData.ideaDescription}
                onChange={handleInputChange}
                placeholder="Enter a brief description of your startup idea (min. 50 characters)"
                required
                className="w-full h-24 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="targetMarket" className="block text-sm font-medium text-gray-700">Target Market</label>
              <input
                id="targetMarket"
                name="targetMarket"
                value={formData.targetMarket}
                onChange={handleInputChange}
                placeholder="Describe your target market (min. 20 characters)"
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        )
      case 3:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="businessModel" className="block text-sm font-medium text-gray-700">Business Model</label>
              <select
                id="businessModel"
                name="businessModel"
                value={formData.businessModel}
                onChange={handleInputChange}
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              >
                <option value="">Select your business model</option>
                <option value="SaaS">SaaS</option>
                <option value="E-commerce">E-commerce</option>
                <option value="Marketplace">Marketplace</option>
                <option value="Subscription">Subscription</option>
                <option value="Freemium">Freemium</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="space-y-2">
              <label htmlFor="uniqueSellingPoint" className="block text-sm font-medium text-gray-700">Unique Selling Point</label>
              <input
                id="uniqueSellingPoint"
                name="uniqueSellingPoint"
                value={formData.uniqueSellingPoint}
                onChange={handleInputChange}
                placeholder="What makes your idea unique? (min. 20 characters)"
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        )
      case 4:
        return (
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-h-screen overflow-y-auto w-full">
            {analysis && (
              <div className="space-y-6">
                {analysis.split('\n\n').map((section, index) => {
                  const lines = section.split('\n').map(line => line.replace(/^[#*\s]+|(\*\*)/g, '').trim());
                  const title = lines[0];
                  const content = lines.slice(1);
                  return (
                    <div key={index} className="border-b border-gray-200 pb-5 last:border-b-0">
                      <h4 className="text-base font-semibold text-gray-800 mb-2">{title}</h4>
                      <ul className="space-y-3">
                        {content.map((item, idx) => (
                          <li key={idx} className="flex items-start">
                            <div className="h-2 w-2 bg-blue-500 rounded-full mr-3 mt-1.5 flex-shrink-0"></div>
                            <span className="text-gray-700 text-sm sm:text-base leading-relaxed">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
                <button
  onClick={() => {
    handleDownload();
    logAnalyticsEvent('download_action', { action: 'download_analysis' });
  }}
  className="mt-8 w-full px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center transition duration-150 ease-in-out shadow-sm"
>
  <Download className="mr-2 h-5 w-5" />
  Download Analysis
</button>
              </div>
            )}
          </div>
        );

      default:
        return null
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col">
      <nav className="bg-white shadow-sm py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <span className="text-2xl font-bold text-blue-600 font-abc">StatAI</span>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/company/trystatai/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BaDKB7QYAQECR%2F9qIWjYBrw%3D%3D" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-gray-700">
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a href="https://x.com/Lily74608592755" className="text-gray-500 hover:text-gray-700">
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="py-6 font-sans">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-semibold mb-2 mt-3 text-gray-800 font-abcc">Validate Your Startup Idea in <span className="text-blue-600">minutes!</span></h2>
          <p className="text-xl font-medium text-gray-600 mb-4">Get AI-powered insights and saves $1000s on market research—completely free</p> <div className="flex flex-col items-center justify-center space-y-2">
            <p className="text-sm text-gray-800 font-semibold">✓ No credit card required</p>
            <p className="text-sm text-gray-800 font-semibold">✓ No login is required</p>
          </div>
        </div>
      </div>
      <main className="flex-grow flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-4xl">
          <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6 font-abcc">Smart Analyzer by <span className="text-semibold">StatAI</span></h2>            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <div className="relative pt-1">
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div style={{ width: `${(step / 4) * 100}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                  </div>
                </div>
                <div className="text-sm text-gray-600 text-center">Step {step} of 4</div>
              </div>
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              {renderStep()}
              <div className="mt-6 flex justify-between">
                {step > 1 && step < 4 && (
                  <button type="button" onClick={handlePrevious} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                    Previous
                  </button>
                )}
                {step < 3 ? (
                  <button type="button" onClick={handleNext} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Next <ArrowRight className="ml-2 h-4 w-4 inline" />
                  </button>
                ) : step === 3 ? (
                  <button type="submit" disabled={isLoading || !apiKey} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50">
                    {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin inline" /> : null}
                    Generate Analysis
                  </button>
                ) : (
                  <button type="button" onClick={() => setStep(1)} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                    Start Over
                  </button>
                )}
              </div>
            </form>
          </div>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <BarChart2 className="h-8 w-8 text-blue-500 mb-2" />
              <h3 className="text-lg font-semibold text-gray-800 mb-2">Market Overview</h3>
              <p className="text-sm text-gray-600">Comprehensive analysis of your target market size and growth potential.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Users className="h-8 w-8 text-green-500 mb-2" />
              <h3 className="text-lg font-semibold text-gray-800 mb-2">Competitor Analysis</h3>
              <p className="text-sm text-gray-600">Detailed insights into your competitors and your unique positioning.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Compass className="h-8 w-8 text-purple-500 mb-2" />
              <h3 className="text-lg font-semibold text-gray-800 mb-2">Next Building Steps</h3>
              <p className="text-sm text-gray-600">Actionable recommendations to move your startup idea forward.</p>
            </div>
          </div>

          <div className="mt-8 flex justify-center space-x-8">
            <div className="flex items-center">
              <Star className="h-5 w-5 text-yellow-400 mr-2 sm:h-6 sm:w-6" />
              <p className="text-xs text-gray-600 font-abcc sm:text-sm">Trusted by Entrepreneurs</p>
            </div>
            <div className="flex items-center">
              <Shield className="h-5 w-5 text-green-500 mr-2 sm:h-6 sm:w-6" />
              <p className="text-xs text-gray-600 font-abcc sm:text-sm">Data-Driven Insights</p>
            </div>
            <div className="flex items-center">
              <TrendingUp className="h-5 w-5 text-blue-500 mr-2 sm:h-6 sm:w-6" />
              <p className="text-xs text-gray-600 font-abcc sm:text-sm">Accelerate Your Growth</p>
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-gray-50 border-t border-gray-200 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center space-y-2">
            <p className="text-center text-base text-gray-400">
              &copy; 2024 StatAI. All rights reserved.
            </p>
            <a href="/sitemap.xml" className="text-sm text-blue-500 hover:text-blue-600">Sitemap</a>
          </div>
        </div>
      </footer>
    </div>
  )
}